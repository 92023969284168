@keyframes preloader {
  from {
    transform: translate(0);
  }
  to {
    transform: translate(0, -100%);
  }
}

@keyframes preloader-glow {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes preloader-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes preloader-hero {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.preloader {
  position: relative;
  z-index: 2;
  background-color: var(--color-black);
  width: 100vw;
  height: 100vh;
  opacity: 1;
  animation: preloader-hero 1s ease-out 1s forwards;
}

.projectPage .preloader {
  animation: preloader-hero 1s ease-out forwards;
}

.preloader__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.preloader__img {
  height: 60px;
  display: block;
  position: absolute;
  top: 0;
  animation: preloader-glow 1.5s ease-out infinite;
}
.page .preloader__overlay {
  position: absolute;
  transform: translate(0, -100%);
  z-index: 3;
  animation: preloader 1s ease-out forwards;
  background-color: var(--color-black);
  width: 100px;
  height: 60px;
}
.page .preloader__img {
  display: block;
  opacity: 0;
  animation: none;
  animation: preloader-fade-in 0.01s ease-out 0.3s forwards;
  height: 60px;
}
